import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Typography,
  Accordion,
  AccordionSummary,
  makeStyles,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ReactComponent as ShoppingCardIcon } from 'shared/assets/svg/shopping-cart.svg';
import { useSelector } from 'react-redux';

const MobileDrawer = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState({
    right: false,
  });
  const c = useStyles();
  const history = useHistory();
  const categories = useSelector((state) => state.categories.categoryList);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpenDrawer({ ...isOpenDrawer, [anchor]: open });
  };

  const redirectToFilteredProducts = (e, value) => {
    history.push('/proizvodi', value);
  };

  const list = (anchor) => (
    <div className={c.list} onKeyDown={toggleDrawer(anchor, false)}>
      <List className={c.listMobile}>
        <Link to="/nasa-prica">
          <ListItem onClick={toggleDrawer(anchor, false)}>
            <ListItemText>Naša priča</ListItemText>
          </ListItem>
        </Link>

        <Link to="/o-nama">
          <ListItem onClick={toggleDrawer(anchor, false)}>
            <ListItemText>O nama</ListItemText>
          </ListItem>
        </Link>

        <ListItem style={{ borderBottom: `1px solid #fff` }}>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon classes={{ root: c.colorWhite }} />}
            >
              <Typography className={c.colorWhite}>Proizvodi</Typography>
            </AccordionSummary>

            <List>
              {categories.map((productCategory, index) => {
                return (
                  <ListItem
                    onClick={toggleDrawer(anchor, false)}
                    key={index}
                    style={{
                      borderBottom:
                        index === productCategory.length - 1
                          ? ``
                          : '1px solid #fff',
                    }}
                  >
                    <ListItemText
                      onClick={(e) =>
                        redirectToFilteredProducts(e, productCategory.name)
                      }
                      className={c.colorWhite}
                    >
                      {productCategory.name}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Accordion>
        </ListItem>

        <Link to="/blog-i-saveti">
          <ListItem onClick={toggleDrawer(anchor, false)}>
            <ListItemText>Saveti</ListItemText>
          </ListItem>
        </Link>

        <Link to="/kontakt">
          <ListItem onClick={toggleDrawer(anchor, false)}>
            <ListItemText>Kontakt</ListItemText>
          </ListItem>
        </Link>

        <ListItem style={{ borderBottom: `1px solid #fff` }}>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon classes={{ root: c.colorWhite }} />}
            >
              <Typography className={c.colorWhite}>
                Dodatne informacije
              </Typography>
            </AccordionSummary>

            <List>
              <Link to="/dostava-i-placanje">
                <ListItem
                  className={c.colorWhite}
                  onClick={toggleDrawer(anchor, false)}
                >
                  <ListItemText>Dostava i plaćanje</ListItemText>
                </ListItem>
              </Link>

              <Link to="/reklamacija-i-povracaj-robe">
                <ListItem
                  className={c.colorWhite}
                  onClick={toggleDrawer(anchor, false)}
                >
                  <ListItemText>Reklamacija i povraćaj robe</ListItemText>
                </ListItem>
              </Link>

              <Link to="/zastita-privatnosti">
                <ListItem
                  className={c.colorWhite}
                  onClick={toggleDrawer(anchor, false)}
                >
                  <ListItemText>Zaštita privatnosti</ListItemText>
                </ListItem>
              </Link>

              <Link to="/prava-i-obaveze-potrosaca">
                <ListItem
                  className={c.colorWhite}
                  onClick={toggleDrawer(anchor, false)}
                >
                  <ListItemText>Prava i obaveze potrošača</ListItemText>
                </ListItem>
              </Link>
            </List>
          </Accordion>
        </ListItem>

        <Link to="/korpa" style={{ paddingLeft: 15 }}>
          <ListItem onClick={toggleDrawer(anchor, false)}>
            <ShoppingCardIcon className={c.cartIcon} />
            {cartItems.length > 0 && (
              <Box component="span" className={c.badge}>
                {cartItems.length}
              </Box>
            )}
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        className={c.menuButton}
        color="inherit"
        onClick={toggleDrawer('right', true)}
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        classes={{ paper: c.paper }}
        anchor={'right'}
        open={isOpenDrawer.right}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        {list('right')}
      </SwipeableDrawer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  listMobile: {
    paddingTop: 0,
    '& a': {
      borderBottom: `1px solid ${theme.palette.common.white}`,
      display: 'block',
    },
    '& p': {
      fontSize: 22,
    },
    '& span': {
      fontSize: 22,
    },
  },
  colorWhite: {
    color: theme.palette.common.white,
  },
  menuButton: {
    paddingRight: 0,
    '& svg': {
      [theme.breakpoints.up('xs')]: {
        fontSize: theme.spacing(4),
      },
    },
  },
  paper: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  cartIcon: {
    width: 35,
    height: 35,
    '& path': {
      stroke: theme.palette.common.white,
    },
  },
  badge: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: '50%',
    padding: '0 0.7rem',
    marginLeft: '0.2rem',
  },
}));

export default MobileDrawer;
