import React from 'react';
import { Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';

import VideoCover from './VideoCover';
import theme from 'libs/theme/theme';
import { Link } from 'react-router-dom';

const VideoBox = ({ headerRef }) => {
  const c = useStyles();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box component="section" position="relative">
      <VideoCover headerRef={headerRef} />
      <Box className={c.videoTextWrapper}>
        <Typography
          variant="h1"
          color="primary"
          className={classNames(
            'text-upper-case animate__animated animate__bounceInLeft',
            isSm ? 'mb4' : 'animate__animated animate__bounceInLeft',
            c.imgCoverTitle
          )}
        >
          Volite sebe!
          {/* Rozi petak! */}
        </Typography>

        <Typography
          variant="body1"
          className={classNames(
            isSm
              ? 'mb4 animate__animated animate__bounceInLeft animate__delay-1s'
              : 'animate__animated animate__bounceInLeft animate__delay-1s',
            c.imgCoverText
          )}
          gutterBottom={true}
        >
          {/* Od 20.11. do 24.11. uz svaku porudžbinu preko 3000din. */}
          {/* <br /> Dobijate 10% popusta i poklon proizvod. */}
          Prirodna kozmetička ulja i kreme u službi vaše lepote!
          <br />
          Jedini nagrađivani proizvođači ulja divlje ruže, maline, kupine u
          Srbiji.
        </Typography>
        <Link
          to="/proizvodi"
          variant="outlined"
          className={classNames(
            'text-upper-case animate__animated animate__bounceInLeft animate__delay-2s',
            c.cta
          )}
        >
          Pogledaj proizvode
        </Link>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  imgCoverTitle: {
    color: '#e35683',
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(8),
    },
    fontWeight: 600,
    fontStyle: 'normal',
  },
  imgCoverText: {
    fontWeight: 700,
    color: '#8c8c8c',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(4),
    },
  },
  videoTextWrapper: {
    background: '#fff',
    padding: 15,
    borderRadius: 8,
    opacity: '0.9',
    fontWeight: 700,
    fontStyle: 'italic',
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      top: '36%',
    },
    [theme.breakpoints.up('md')]: {
      top: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      top: '42%',
    },
    left: '5vw',
    color: '#fff',
    transform: 'translateY(-50%)',
    zndex: 1111,
    width: '50%',
  },
  cta: {
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      fontSize: 12,
      padding: '8px 16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(2),
      padding: '10px 20px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(2),
      padding: '20px 40px',
    },
    fontWeight: 700,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    color: theme.palette.common.white,
    border: `1px solid${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;`,
    },
  },
}));

export default VideoBox;
