import React, { useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  List,
  Grid,
  Button,
  ListItem,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { addToCart } from 'libs/redux/actions/cartActions';
import { MessageBox, ProductCart } from 'components';
import { Link, useHistory } from 'react-router-dom';
import { DotPrice } from 'shared/functions/DotPrice.js';
import theme from 'libs/theme/theme';
import InitiateCheckout from 'ConversionAPIs/InitiateCheckout';

const CartPage = (props) => {
  const c = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const productId = props.match.params.id;
  const qty = props.location.search
    ? Number(props.location.search.split('=')[1])
    : 1;

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty, history.location.state));
    }
  }, [dispatch, productId, qty, history]);

  const cartItems = useSelector((state) => state.cart.cartItems);

  let price = cartItems.reduce((a, c) => a + c.price * c.qty, 0);
  let finallPriceText = 'Ukupno';

  // if (price > 3000) {
  //   const discountPrice = price * 0.1;
  //   price = price - discountPrice;
  //   finallPriceText = 'Ukupno sa 10% popusta';
  // }

  const finallPrice = price < 5000 ? price + 320 : price;

  return (
    <Container maxWidth="xl" className="padding-spacing">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" className={'title textColor'}>
            Vaša korpa
          </Typography>
          <>
            {cartItems.length === 0 ? (
              <MessageBox>
                Vaša korpa je trenutno prazna.{' '}
                <Link to="/proizvodi">Natrag u kupovinu</Link>
              </MessageBox>
            ) : (
              <>
                <List>
                  {cartItems.map((cartItem, index) => {
                    return <ProductCart key={index} product={cartItem} />;
                  })}

                  <ListItem>
                    <Grid container className={classNames('bkg-cover', 'p1')}>
                      <Grid item xs={1} sm={8} />
                      <Grid item xs={6} sm={2}>
                        <Typography className="title">Dostava:</Typography>
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <Typography
                          className={isXs ? 'text-align-end' : 'inherit'}
                        >
                          {price < 5000 ? '320,00 din' : '0 din'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>

                  <ListItem>
                    <Grid container className={classNames('bkg-cover', 'p1')}>
                      <Grid item xs={1} sm={8} />

                      <Grid item xs={6} sm={2}>
                        <Typography className="title">
                          {finallPriceText}:
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <Typography
                          className={isXs ? 'text-align-end' : 'inherit'}
                        >
                          {DotPrice(finallPrice)}
                          ,00 din
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>

                  <ListItem>
                    <Box
                      p={1}
                      width={1}
                      className={classNames('bkg-cover', 'text-align-center')}
                    >
                      <Typography className="title">
                        Kurirska služba - Post Express
                      </Typography>
                      <Typography>Cena dostave je 320,00 din</Typography>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <Box
                      p={1}
                      width={1}
                      className={classNames('bkg-cover', 'text-align-center')}
                    >
                      <Typography className="title">
                        Porudžbine preko 5000,00 din dostava gratis
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <Box
                      p={1}
                      width={1}
                      className={classNames('bkg-cover', 'text-align-center')}
                    >
                      <Typography>
                        <span className="title">*</span>ako sajt ne prikaže cenu
                        nekog proizvoda, ispraznite korpu i osvežite stranicu
                      </Typography>
                    </Box>
                  </ListItem>
                </List>

                <Box xs={2} display="flex" justifyContent="space-around">
                  <Button
                    variant="outlined"
                    className={c.btnBrowseShopping}
                    onClick={() => history.push('/proizvodi')}
                  >
                    Razgledaj dalje
                  </Button>

                  <Button
                    variant="outlined"
                    className={c.btnOrderNow}
                    onClick={() => {
                      history.push('/korpa/naruci');
                      InitiateCheckout();
                    }}
                    disabled={cartItems.length === 0}
                  >
                    Želim naručiti
                  </Button>
                </Box>
              </>
            )}
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  btnBrowseShopping: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.spacing(3),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  btnOrderNow: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.spacing(3),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  postExpressAnchor: {
    color: theme.palette.primary.main,
  },
}));

export default CartPage;
