import React from 'react';
import { Container, Typography, Grid } from '@material-ui/core';

const OurStory = () => {
  return (
    <Container
      maxWidth="lg"
      className="lg-mg-bottom, padding-spacing textColor "
    >
      <Grid container className="bkg-cover mb4">
        <Grid item xs={12} className={'padding-spacing-2'}>
          <Typography variant="h2" gutterBottom={true} className="title">
            Naša priča
          </Typography>

          <Typography gutterBottom={true}>
            Naš brend <span className="title">Plemenita ulja</span> nastao je
            2015. godine, iz želje da spojimo prirodu i nauku u službi lepote i
            zdravlja. Iza svega stojimo nas dvoje{' '}
            <span className="title">– Miroslav i Marina Garić – </span>
            partneri u životu i poslu, s velikim snovima i još većom ljubavlju
            prema onome što radimo.
          </Typography>

          <Typography gutterBottom={true}>
            Sve je počelo s uljem divlje ruže. Isprva je to bio samo mali
            eksperiment, ali ubrzo smo shvatili da u rukama imamo pravo blago.
            To ulje nije bilo samo proizvod – bilo je{' '}
            <span className="title">rešenje, pomoć i podrška</span> ljudima koji
            su se suočavali s problemima poput bora, ožiljaka, strija i akni.
          </Typography>
          <Typography gutterBottom={true}>
            Kako je vreme prolazilo, razvili smo čitavu liniju proizvoda: ulja
            od kupine, maline, šljive i kajsije, zatim mikseve ulja, kreme,
            butere, šampone, pa čak i dezodoranse bez aluminijuma i soli.{' '}
            <span className="title">Naš cilj</span> je uvek bio isti – da
            ponudimo kvalitet, autentičnost i nešto što zaista čini razliku.
          </Typography>

          <Typography gutterBottom={true}>
            Ponosni smo na to što smo među prvima u Srbiji započeli proizvodnju
            hladno ceđenih ulja i što smo pomogli ljudima da prepoznaju njihove
            prednosti. Ljudi su nas zapamtili, učili uz nas, i sada nam veruju.
          </Typography>
          <Typography gutterBottom={true}>
            Danas možete čuti o nama i našem brendu u raznim člancima i pričama
            ali i na instagramu, fb i tik toku. Ali ono što nas najviše raduje
            jeste poverenje i podrška naših kupaca. Svi vi, koji verujete u
            prirodu i birate proizvode sa srcem, deo ste ove priče.
          </Typography>
          <Typography gutterBottom={true}>
            <span className="title">Plemenita ulja</span> nisu samo brend – to
            je spoj ljubavi, rada i posvećenosti.
          </Typography>

          <Typography gutterBottom={true}>
            Pozivamo vas da se pridružite našoj zajednici i osetite magiju
            prirode u njenom najčistijem obliku.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OurStory;
